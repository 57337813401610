@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;300&family=Cabin&family=Shantell+Sans:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif !important;
}

body {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #F4F7FF !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    background-color: #071D45 !important;
}